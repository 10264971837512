<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.history") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 600px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.time") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.source") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.source_code") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.function") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.account") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.available") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.system_response") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.status") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.classify") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.tracking") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="item in actionItems" :key="item.id">
              <td>
                {{ formatDateTime(item.created_at, "HH:mm:ss DD/MM/YYYY") }}
              </td>
              <td>{{ $t(`labels.uid_source_type_${item.source_type}`) }}</td>
              <td>{{ item.warehouse_code || item.pos_code }}</td>
              <td>{{ $t(`labels.uid_action_type_${item.action_type}`) }}</td>
              <td>{{ item.identity_name }}</td>
              <td>
                <span :class="{ 'error--text': !item.available }">{{
                  item.available ? "Yes" : "No"
                }}</span>
              </td>
              <td>
                <span :class="{ 'error--text': !item.system_status }">{{
                  item.system_status ? "Yes" : "No"
                }}</span>
              </td>
              <td>{{ $t(`labels.uid_status_${item.status}`) }}</td>
              <td>{{ $t(`labels.uid_order_type_${item.order_type}`) }}</td>
              <td>
                <a
                  v-if="item.tracking_id"
                  :href="trackingLink(item)"
                  target="_blank"
                >
                  {{ item.tracking_id }}
                </a>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 600px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <!-- <thead class="v-data-table-header">
            <tr>
              <th
                role="columnheader"
                class="text-center error--text"
                style="width: 115px"
              >
                {{ $t("labels.time") }}
              </th>
              <th
                role="columnheader"
                class="text-center error--text"
                style="width: 115px"
              >
                {{ $t("labels.warehouse") }}
              </th>
              <th
                role="columnheader"
                class="text-center error--text"
                style="width: 115px"
              >
                {{ $t("labels.pos") }}
              </th>
              <th
                role="columnheader"
                class="text-center error--text"
                style="width: 200px"
              >
                {{ $t("labels.order_online") }}
              </th>
              <th role="columnheader" class="text-center error--text">
                {{ $t("labels.order_pos") }}
              </th>
              <th role="columnheader" class="text-center error--text">
                {{ $t("labels.order_transfer") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(oItem, oKey) in orderItems"
              :key="`o_${oKey}`"
            >
              <td>{{ formatDateTime(oItem.created_at) }}</td>
              <td>{{ oItem.warehouse_code }}</td>
              <td>{{ oItem.pos_code || oItem.po_pos_code }}</td>
              <td>
                {{ !oItem.id_goods_receipt ? oItem.order_online_tracking : "" }}
              </td>
              <td>{{ oItem.order_pos_tracking }}</td>
              <td>
                {{ oItem.id_goods_receipt ? oItem.order_online_tracking : "" }}
              </td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
          </tbody>

          <thead class="v-data-table-header">
            <tr>
              <th
                role="columnheader"
                class="text-center primary--text"
                style="width: 115px"
              >
                {{ $t("labels.time") }}
              </th>
              <th
                role="columnheader"
                class="text-center primary--text"
                style="width: 115px"
              >
                {{ $t("labels.warehouse") }}
              </th>
              <th
                role="columnheader"
                class="text-center primary--text"
                style="width: 115px"
              >
                {{ $t("labels.pos") }}
              </th>
              <th
                role="columnheader"
                class="text-center primary--text"
                style="width: 200px"
              >
                {{ $t("labels.employee") }}
              </th>
              <th role="columnheader" class="text-center primary--text">
                {{ $t("labels.action") }}
              </th>
              <th role="columnheader" class="text-center primary--text">
                {{ $t("labels.basket_code_or_location") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(aItem, aKey) in actionItems"
              :key="`a_${aKey}`"
            >
              <td>{{ formatDateTime(aItem.created_at) }}</td>
              <td>{{ aItem.warehouse_code }}</td>
              <td>{{ aItem.pos_code }}</td>
              <td>{{ aItem.employee_name }}</td>
              <td>{{ aItem.action_name }}</td>
              <td>{{ aItem.basket_code || aItem.cell_id }}</td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
          </tbody> -->

          <template v-if="checkPermission(['supper_admin'])">
            <thead class="v-data-table-header">
              <tr>
                <th
                  role="columnheader"
                  class="text-center purple--text"
                  style="width: 115px"
                >
                  {{ $t("labels.time") }}
                </th>
                <th
                  role="columnheader"
                  class="text-center purple--text"
                  style="width: 115px"
                >
                  {{ $t("labels.warehouse") }}
                </th>
                <th
                  role="columnheader"
                  class="text-center purple--text"
                  style="width: 115px"
                >
                  {{ $t("labels.pos") }}
                </th>
                <th role="columnheader" colspan="3">
                  <div class="d-flex align-center">
                    <div style="width: 184px" class="purple--text text-center">
                      {{ $t("labels.key") }}
                    </div>
                    <div
                      style="width: calc(100% - 184px)"
                      class="purple--text text-center"
                    >
                      {{ $t("labels.value") }}
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lItem, lKey) in logItems" :key="`l_${lKey}`">
                <td style="vertical-align: top" class="text-center">
                  {{ formatDateTime(lItem.created_at) }}
                </td>
                <td style="vertical-align: top" class="text-center">
                  {{ lItem.warehouse_code }}
                </td>
                <td style="vertical-align: top" class="text-center">
                  {{ lItem.pos_code }}
                </td>
                <td colspan="3">
                  <div
                    v-for="(log, lr) in lItem.items"
                    :key="`lr_${lr}`"
                    class="mb-1"
                  >
                    <v-row no-gutters v-if="log.value[0] || log.value[1]">
                      <v-col cols="5">{{ log.key }}</v-col>
                      <v-col cols="7">
                        <template v-if="lItem.action === 'update'">
                          <span class="font-italic grey--text"
                            >{{ log.value[0] || "NULL" }}
                          </span>
                          →
                        </template>
                        <span class="primary--text">
                          {{ log.value[1] || "NULL" }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatDateTime } from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  name: "UidHistory",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    positionItems: [],
    actionItems: [],
    orderItems: [],
    logItems: [],
  }),
  mounted() {
    this.getHistory();
  },
  methods: {
    formatDateTime,
    close() {
      this.$emit("close", true);
    },
    trackingLink(item) {
      if (
        item.goods_issue_detail_tracking_id ||
        item.goods_issue_detail_return_tracking_id
      ) {
        return `/goods-issue/detail?tracking_id=${item.tracking_id}`;
      } else if (
        item.pos_order_tracking_id ||
        item.pos_order_return_tracking_id
      ) {
        return `/pos-order/detail?tracking_id=${item.tracking_id}`;
      } else if (item.goods_receipt_tracking_id) {
        return `/goods-receipt/list?goods_receipt_tracking=${item.tracking_id}&sku=${this.item.sku}`;
      }
    },
    getHistory() {
      httpClient
        .post("/goods/v1/uid-history", {
          id: this.item.id,
        })
        .then(({ data }) => {
          const { actions, logs } = data;
          // this.positionItems = [...positions];
          // this.orderItems = [...orders];
          this.actionItems = [...actions];
          this.logItems = [...logs].map((item) => {
            const log_data = JSON.parse(item.log_data);
            const keys = Object.keys(log_data);
            const items = [];
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              items.push({
                key,
                value: log_data[key].split(" => "),
              });
            }
            return {
              ...item,
              items,
            };
          });
        });
    },
  },
};
</script>
